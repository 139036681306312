import { Typography, Box, Card, CardActionArea, Grid } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';

import serviceIc1 from '../../assets/images/serviceIc1.png';
import serviceIc2 from '../../assets/images/serviceIc2.png';
import serviceIc3 from '../../assets/images/serviceIc3.png';
import serviceIc4 from '../../assets/images/serviceIc4.png';
import serviceIc5 from '../../assets/images/serviceIc5.png';
import serviceIc6 from '../../assets/images/serviceIc6.png';
import serviceIc7 from '../../assets/images/serviceIc7.png';
import serviceIc8 from '../../assets/images/serviceIc8.png';

import serviceIc9 from '../../assets/images/serviceIc9.png';
import serviceIc10 from '../../assets/images/serviceIc10.png';
import serviceIc11 from '../../assets/images/serviceIc11.png';
import serviceIc12 from '../../assets/images/serviceIc12.png';

const StyledCardActionArea = styled(CardActionArea)({
    // padding: '20px',
});

function ServicesBlock() {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ mb: 2, fontWeight: 600 }}>
                Services
            </Typography>
            <Typography sx={{ px: { md: 20, sm: 0 }, mb: 4 }}>
                Medical & Pharmaceutical Linguistics and Consulting services for Pharmaceutical, Clinical Trials,
                Medical Devices, and Healthcare industries
            </Typography>

            <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, lg: 2 }}>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc1} alt="Translation" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc2} alt="Editing" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc3} alt="Manuscripts Translation" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc4} alt="Interpretation" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc5} alt="PROs Linguistic Validation" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc6} alt="PROs Creation" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc7} alt="IT Localisation" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc8} alt="Marketing Consalting" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc9} alt="Cultural Consulting" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc10} alt="Medica lCopywriting" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc11} alt="Scientific Writing" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <StyledCardActionArea>
                            <img src={serviceIc12} alt="DTP Services" width="100%" />
                        </StyledCardActionArea>
                    </Card>
                </Grid>
                {/* </Box> */}
            </Grid>
        </Box>
    );
}

export default ServicesBlock;
