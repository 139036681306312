import { Box, Typography } from '@mui/material';
import React from 'react';

function SolutionsBlock() {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ mb: 2, fontWeight: 600 }}>
                Full Spectrum Solutions
            </Typography>
            <Typography sx={{ px: { md: 16, sm: 0 } }}>
                Full Spectrum of Medical & Pharmaceutical Linguistics and Consulting solutions in English to Russian
                (Russian to English) and English to Belarusian (Belarusian to English) language pairs for End Customers
                from Pharmaceutical, Clinical Trials, Medical Devices, and Healthcare industries.
            </Typography>
        </Box>
    );
}

export default SolutionsBlock;
