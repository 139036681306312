/* eslint-disable react/react-in-jsx-scope */
import { Box, Container } from '@mui/material';
// import { styled } from '@mui/material/styles';
import Footer from '../component/Footer';

import { MainSlider } from '../component/MainBanner';
import ServicesBlock from '../component/ServicesBlock';
import SolutionsBlock from '../component/SolutionsBlock';

// const Block = styled(Box)({
//     margin: '100px 0',
// });

function About() {
    return (
        <Box>
            <MainSlider />
            <Container>
                <Box sx={{ my: { md: 12, sm: 8, xs: 6 } }}>
                    <SolutionsBlock />
                </Box>
                <Box sx={{ my: { md: 12, sm: 8, xs: 6 } }}>
                    <ServicesBlock />
                </Box>
            </Container>
            <Footer />
        </Box>
    );
}
export default About;
