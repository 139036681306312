/* eslint-disable */

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './component/Navbar';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Faq from './pages/faq';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';

function App() {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Router>
                    <Navbar />
                    <Routes>
                        {/* <Route exact path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/faq" element={<Faq />} /> */}
                        <Route exact path="/" element={<Home />} />
                        <Route path="/about" element={<Home />} />
                        <Route path="/services" element={<Home />} />
                        <Route path="/processes" element={<Home />} />
                        <Route path="/solutions" element={<Home />} />
                        <Route path="/get-in-touch" element={<Home />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </>
    );
}
export default App;
