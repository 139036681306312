/* eslint-disable  */

import React from 'react';
import { AppBar, Toolbar, Box, CssBaseline, useTheme, useMediaQuery, Container, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import DrawerComponent from './Drawer';
import Logo from '../assets/images/logo.png';
import { manuItems } from '../commons/menuItems.js';

const CustomLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'white',
    fontSize: '20px',
    // marginLeft: theme.spacing(20),
    '&:hover': {
        // color: theme.palette.secondary.main,
        borderBottom: `1px solid ${theme.palette.common.white}`,
    },
}));

function Navbar() {
    // const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Divider sx={{ height: '4px', backgroundColor: theme.palette.secondary.main }} />

            <Box sx={{ my: 2 }}>
                <Container>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Link to="/">
                            <img src={Logo} alt="clinlex" />
                        </Link>
                        {isMobile && <DrawerComponent />}
                    </Box>
                </Container>
            </Box>
            {!isMobile && (
                <AppBar position="static" elevation={0}>
                    <CssBaseline />
                    <Toolbar>
                        <Container>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                {manuItems.map((item) => (
                                    <CustomLink key={item.id} to={item.link}>
                                        {item.title}
                                    </CustomLink>
                                ))}
                            </Box>
                        </Container>
                    </Toolbar>
                </AppBar>
            )}
            {isMobile && <Divider sx={{ height: '4px', backgroundColor: theme.palette.primary.main }} />}
        </Box>
    );
}
export default Navbar;
