/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import MainBanner from '../../assets/images/MainBanner.png';
import MainBanner2 from '../../assets/images/MainBanner2.png';
import MainBanner3 from '../../assets/images/MainBanner3.png';

// const slideImages = [MainBanner, MainBanner2, MainBanner3];

export function MainSlider() {
    const properties = {
        duration: 3000,
        infinite: true,
        autoplay: true,
        indicators: false,
        arrows: false,
    };

    return (
        // <div>
        //     <img src={Banner} alt="" style={{ width: '100%' }} />
        // </div>
        <div>
            <Slide easing="ease" {...properties}>
                <div className="each-slide">
                    {/* <div style={{ backgroundImage: `url(${slideImages[0]})` }} /> */}
                    <img src={MainBanner} alt="" style={{ width: '100%' }} />
                </div>
                <div className="each-slide">
                    {/* <div style={{ backgroundImage: `url(${slideImages[1]})` }} /> */}
                    <img src={MainBanner2} alt="" style={{ width: '100%' }} />
                </div>
                <div className="each-slide">
                    <div>
                        <img src={MainBanner3} alt="" style={{ width: '100%' }} />
                    </div>
                </div>
            </Slide>
        </div>
    );
}
